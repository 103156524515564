/*
 * Component: Form
 * ---------------
 */
.form-control {
  @include border-radius($input-radius);
  box-shadow: none;
  border-color: lighten($gray-light, 30);
  &:focus {
    border-color: $light-blue;
    box-shadow: none;
  }
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bbb;
    opacity: 1;
  }

  //&:not(select) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  //}
}



.form-group {
  &.has-success {
    label {
      color: $green;
    }
    .form-control {
      border-color: $green;
      box-shadow: none;
    }
    .help-block {
      color: $green;
    }
  }

  &.has-warning {
    label {
      color: $yellow;
    }
    .form-control {
      border-color: $yellow;
      box-shadow: none;
    }
    .help-block {
      color: $yellow;
    }
  }

  &.has-error {
    label {
      color: $red;
    }
    .form-control {
      border-color: $red;
      box-shadow: none;
    }
    .help-block {
      color: darken($red, 25);
      //      color: $red;
    }
  }
}

/* Input group */
.input-group {
  .input-group-addon {
    @include border-radius($input-radius);
    border-color: lighten($gray-light, 30);
    background-color: #fff;
  }
}

/* button groups */
.btn-group-vertical {
  .btn {
    &.btn-flat:first-of-type, &.btn-flat:last-of-type {
      @include border-radius(0);
    }
  }
}

.icheck > label {
  padding-left: 0;
}

/* support Font Awesome icons in form-control */
.form-control-feedback.fa {
  line-height: $input-height-base;
}

.input-lg + .form-control-feedback.fa,
.input-group-lg + .form-control-feedback.fa,
.form-group-lg .form-control + .form-control-feedback.fa {
  line-height: $input-height-large;
}

.input-sm + .form-control-feedback.fa,
.input-group-sm + .form-control-feedback.fa,
.form-group-sm .form-control + .form-control-feedback.fa {
  line-height: $input-height-small;
}
