/*
 * Component: Sidebar
 * ------------------
 */
//Main Sidebar
// ``` .left-side has been deprecated as of 2.0.0 in favor of .main-sidebar ```

.main-sidebar,
.left-side {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: $navbar-height;
  min-height: 100%;
  width: $sidebar-width;
  z-index: 810;
  //Using disposable variable to join statements with a comma
  $transition-rule: $transition-speed $transition-fn,
  width $transition-speed $transition-fn;
  @include transition-transform($transition-rule);
  @media (max-width: $screen-header-collapse) {
    padding-top: 100px;
  }
  @media (max-width: $screen-header-collapse) {
    #x-sidebar-collapse ~ .wrapper &{
      @include translate(-$sidebar-width, 0);
    }
    #x-sidebar-collapse:checked ~ .wrapper &{
      @include translate(0, 0);
    }
  }

  .sidebar-collapse & {
    @media (min-width: $screen-sm) {
      @include translate(-$sidebar-width, 0);
    }
  }
  .sidebar-open & {
    @media (max-width: $screen-xs-max) {
      @include translate(0, 0);
    }
  }
}

.sidebar {
  padding-bottom: 10px;
}

// remove border from form
.sidebar-form {
  input:focus {
    border-color: transparent;
  }
}

//Sidebar user panel
.user-panel {
  position: relative;
  width: 100%;
  padding: 16px;
  overflow: hidden;
  @include clearfix();
  > .image > img {
    width: 100%;
    max-width: 45px;
    height: auto;
  }
  > .info {
    padding: 5px 5px 5px 15px;
    line-height: 1;
    position: absolute;
    left: 55px;
    > p {
      font-weight: 600;
      margin-bottom: 9px;
    }
    > a {
      text-decoration: none;
      padding-right: 5px;
      margin-top: 3px;
      font-size: 11px;
      > .fa,
      > .ion,
      > .glyphicon {
        margin-right: 3px;
      }
    }
  }
}

// Sidebar menu
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  //First Level
  > li {
    position: relative;
    margin: 0;
    padding: 0;
//    border-bottom:1px solid lighten($dark-background,8);
    > a {
      padding: 8px 10px;
      display: block;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 700;
      > .fa,
      > .glyphicon,
      > .ion {
        $icon-size-sidebar:26px;
        width: $icon-size-sidebar;
        vertical-align: middle;
        margin-right: 6px;
        height: $icon-size-sidebar;
        text-align: center;
        line-height: $icon-size-sidebar;
        color: lighten($dark-background,30);
        font-size: 1.6em;
      }
    }
    .label,
    .badge {
      margin-right: 5px;
    }
    .badge {
      margin-top: 3px;
    }
  }
  li.header {
    padding: 10px 25px 10px 15px;
    font-size: 12px;
  }
  li > a > .pull-right-container > .sidebar-caret {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px;
  }
  li.active {
    > a > .pull-right-container > .sidebar-caret {
      @include rotate(-90deg);
    }
    i {
      color:white !important;
    }
    > .treeview-menu {
      display: block;
    }
  }

  // Tree view menu
  .treeview-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 0px;
    .treeview-menu {
      padding-left: 20px;
    }
    > li {
//      border-bottom:1px solid rgba(black,.04);
      &:nth-child(even) {
//        background-color: rgba(white,.02);
      }
      margin: 0;
      > a {
        padding: 8px 5px 8px 40px;
        display: block;
        font-weight: 700;
        &:hover {
          background: $dark-background;
        }
        > .fa,
        > .glyphicon,
        > .ion {
          width: 20px;
        }
        > .pull-right-container > .fa-angle-left,
        > .fa-angle-down {
          width: auto;
        }
      }
    }
  }
}
