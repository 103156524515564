//AdminLTE 2 Variables.less
//=========================

//PATHS
//--------------------------------------------------------

$boxed-layout-bg-image-path: "../img/boxed-bg.jpg";

//COLORS
//--------------------------------------------------------
//Primary
$light-blue: $brand-primary;
//Danger
$red: $brand-danger;
//Success
$green: $brand-success;
//Info
$aqua: $brand-info;
//Warning
$yellow: $brand-warning;
$blue: #164464;
$navy: #001F3F;
$teal: #39CCCC;
$olive: #3D9970;
$lime: #01cb63;
$orange: #FF851B;
$fuchsia: #d911ab;
$purple: #605ca8;
$maroon: #D81B60;
$black: #111;
$gray: #d2d6de;



//LAYOUT
//--------------------------------------------------------

//Side bar and logo width
$sidebar-width: 200px;
//Boxed layout maximum width
$boxed-layout-max-width: 1024px;
//When the logo should go to the top of the screen
$screen-header-collapse: $screen-xs-max;

//Link colors (Aka: <a> tags)
$link-color: $light-blue;
$link-hover-color: lighten($link-color, 15%);


//SIDEBAR SKINS
//--------------------------------------------------------

//Dark sidebar
$sidebar-dark-bg: lighten($dark-background,5);
$sidebar-dark-hover-bg: darken($sidebar-dark-bg, 2%);
$sidebar-dark-color: lighten($sidebar-dark-bg, 75%);
$sidebar-dark-hover-color: #fff;
$sidebar-dark-submenu-bg: lighten($sidebar-dark-bg, 5%);
$sidebar-dark-submenu-color: lighten($sidebar-dark-submenu-bg, 85%);
$sidebar-dark-submenu-hover-color: #fff;

//Light sidebar
$sidebar-light-bg: #f9fafc;
$sidebar-light-hover-bg: lighten(#f0f0f1, 1.5%);
$sidebar-light-color: #444;
$sidebar-light-hover-color: #000;
$sidebar-light-submenu-bg: $sidebar-light-hover-bg;
$sidebar-light-submenu-color: #777;
$sidebar-light-submenu-hover-color: #000;

//CONTROL SIDEBAR
//--------------------------------------------------------
$control-sidebar-width: $sidebar-width;

//BOXES
//--------------------------------------------------------
$box-border-color: $gray-lighter;
$box-border-radius: 0px;
$box-footer-bg: #fff;
$box-boxshadow: 0 1px 1px rgba(0, 0, 0, .1);
$box-padding: 20px;

//Box variants
$box-default-border-top-color: #d2d6de;

//BUTTONS
//--------------------------------------------------------
$btn-boxshadow: none;

//PROGRESS BARS
//--------------------------------------------------------
$progress-bar-border-radius: 1px;
$progress-bar-sm-border-radius: 1px;
$progress-bar-xs-border-radius: 1px;

//FORMS
//--------------------------------------------------------
$input-radius: 0;

//BUTTONS
//--------------------------------------------------------

//Border radius for non flat buttons
$btn-border-radius: 2px;
$btn-border-radius-rounded: 64px;

//DIRECT CHAT
//--------------------------------------------------------
$direct-chat-height: 250px;
$direct-chat-default-msg-bg: $gray-lighter;
$direct-chat-default-font-color: #444;
$direct-chat-default-msg-border-color: $gray;

//CHAT WIDGET
//--------------------------------------------------------
$attachment-border-radius: 3px;

//TRANSITIONS SETTINGS
//--------------------------------------------------------

//Transition global options
$transition-speed: .3s !default;
$transition-fn: ease-in-out;
//cubic-bezier(0.32,1.25,0.375,1.15);

