.panel-announcement {
  .panel-heading {
    padding-bottom: 3px;
    text-transform: uppercase;
    font-weight: bold;
    @include headings() {
      font-size: 14px;
      color: $eastern-blue
    }

  }
  .panel-body {
    padding-top: 0;
    @include headings() {
      font-size: 16px;
    }
  }
}
.panel-footer {
  padding:$grid-gutter-width / 2 ;
}
.panel {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.panel-body .panel {
  border:1px solid darken($gray-lighter,4)
}