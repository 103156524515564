$iluo-size: 30px;
$iluo-bar-width: 4px;
$iluo-size-sm: 16px;
$iluo-bar-width-sm: 3px;
$iluo-size-lg: 50px;
$iluo-bar-width-lg: 6px;

.iluo-icon {
  display: block;
  margin: $main-distance / 2;
  height: $iluo-size;
  width: $iluo-size;
  position: relative;
  span {
    border-radius: 6px;
    width: $iluo-size;
    height: $iluo-bar-width;
    display: block;
    position: absolute;
    background-color: $gray-lighter;
    &:nth-child(1) {
      top: 0;
      left: $iluo-bar-width;
      transform: rotateZ(90deg);
      transform-origin: 0% 0%;
      z-index: 5;
    }
    &:nth-child(2) {
      bottom: 0;
      left: 0;
      z-index: 4;

    }
    &:nth-child(3) {
      bottom: 0;
      right: $iluo-bar-width;
      transform: rotateZ(90deg);
      transform-origin: 100% 100%;
      z-index: 3;

    }
    &:nth-child(4) {
      top: 0;
      left: 0;
      z-index: 2;

    }
  }
  //  Levels
  &.level1 {
    span:nth-child(-n+1) {
      background-color: $brand-info;
    }
  }
  &.level2 {
    span:nth-child(-n+2) {
      background-color: $brand-primary;
    }
  }
  &.level3 {
    span:nth-child(-n+3) {
      background-color: $brand-warning;
    }
  }
  &.level4 {
    span:nth-child(-n+4) {
      background-color: $brand-success;
    }
  }
}


.iluo-icon-sm {
  margin: $main-distance / 2;
  height: $iluo-size-sm;
  width: $iluo-size-sm;
  span {
    width: $iluo-size-sm;
    height: $iluo-bar-width-sm;
    &:nth-child(1) {
      left: $iluo-bar-width-sm;
    }
    &:nth-child(3) {
      right: $iluo-bar-width-sm;
    }
  }
}

.iluo-icon-lg {
  margin: $main-distance / 2;
  height: $iluo-size-lg;
  width: $iluo-size-lg;
  span {
    width: $iluo-size-lg;
    height: $iluo-bar-width-lg;
    &:nth-child(1) {
      left: $iluo-bar-width-lg;
    }
    &:nth-child(3) {
      right: $iluo-bar-width-lg;
    }
  }
}