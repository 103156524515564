/*
 * Skin: one
 * -----------
 */

/* skin-one navbar */
.skin-one {
  //Navbar & Logo
  .main-header {
    @include box-shadow(0px 1px 1px rgba(0, 0, 0, 0.05));
    .navbar-toggle {
    }
    .navbar-brand {
      color: #333;
      border-right: 1px solid #eee;
    }
    .navbar {
      @include navbar-variant($dark-background, lighten($dark-background,40), white, lighten($dark-background,5));
      > .sidebar-toggle {
        color: lighten($dark-background,40);
        font-weight: initial;
        border-right: 1px solid lighten($dark-background,5);
      }
      .navbar-nav {
        > li > a {
          border-right: 1px solid lighten(#272b36,5);
        }
      }
      .navbar-custom-menu .navbar-nav,
      .navbar-right {
        > li {
          > a {
            >i {
              font-size: 1.3em;
            }
//            border-left: 1px solid lighten(#272b36,5);
            border-right-width: 0;
          }
        }
      }
    }
    > .logo {

      @include logo-variant(darken($dark-background,4), $teal);

      @media (max-width: $screen-header-collapse) {
        @include logo-variant(darken($dark-background,4), $teal);
        border-right: none;


      }
    }

    li.user-header {
      background-color: #222;
    }
  }

  //Content Header
  .content-header {
    background: transparent;
    box-shadow: none;
  }
  //Create the sidebar skin
  @include skin-dark-sidebar(#fff);

  .pace {
    .pace-progress {
      background: #222;
    }
    .pace-activity {
      border-top-color: #222;
      border-left-color: #222;
    }
  }

}
