$icon-size: 80px;
$icon-radius: 3%;
$spacing: $main-distance;

@mixin gradientBackground($color) {
  background: linear-gradient(135deg, darken($color, 10), darken($color, 30));
}

@function set-color($color) {
  @if (lightness($color) > 60) {
    @return #000;
  } @else {
    @return #FFF;
  }
}

@mixin iconVariant($color) {
  .inner-icon {
    @include gradientBackground($color);
  }
  i {
    color: lighten($color, 10);
    text-shadow: 0px 0px 10px rgba(black, .1);
  }
  span {
  }
}

.xenon-icon {
  display: inline-block;
  margin-right: $main-distance;
  vertical-align: top;
  margin-bottom: $spacing;
  position: relative;
  color: white;
  transition: all .3s ease;
  .inner-icon {
    background-color: $gray-light;
    border-radius: $icon-radius;
    display: block;
    position: relative;
    height: $icon-size;
    width: $icon-size;
    margin-bottom: $main-distance / 3;
  }
  i {
    font-size: $icon-size * .65;
    line-height: $icon-size;
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
    transition: all .3s ease;

  }
  span {
    font-weight: bold;
    text-align: center;
    display: block;
    width: 100%;
    color: $text-color;
  }
  &:active {
    transform: scale(.95);
  }
  &:hover {
    text-decoration: none;
    transform: scale(1.03);
    i {
      color: white;
    }
  }
  .notifications {
    position: absolute;
    top: -10px;
    right: -10px;
    display: block;
    width: ceil($icon-size / 3);
    height: ceil($icon-size / 3);
    line-height: floor($icon-size / 3);
    border-radius: 100%;
    background-color: #ff2438;
    color: white;
    box-shadow: 0px 1px 2px 0px rgba(black, .2);
    font-weight: normal;
  }
}

.xenon-icon- {

  &primary {
    @include iconVariant($brand-primary);
  }
  &info {
    @include iconVariant($brand-info);
  }
  &warning {
    @include iconVariant($brand-warning);
  }
  &danger {
    @include iconVariant($brand-danger);
  }
  &orange {
    @include iconVariant(#ff4016);
  }
  &neon {
    @include iconVariant(#0fff9c);
  }
  &fuchsia {
    @include iconVariant(#ff004e);
  }
  &cream {
    @include iconVariant(#ffd063);
  }
  &lightgreen {
    @include iconVariant(#12ff77);
  }
  &pink {
    @include iconVariant(#ff0ccd);
  }
  &purple {
    @include iconVariant(#932aff);
  }
  &teal {
    @include iconVariant($teal);
  }
  &gray {
    @include iconVariant($gray);
  }
  &black {
    @include iconVariant($black);
  }
  &red {
    @include iconVariant($red);
  }
  &yellow {
    @include iconVariant($yellow);
  }
  &aqua {
    @include iconVariant($aqua);
  }
  &blue {
    @include iconVariant($blue);
  }
  &light-blue {
    @include iconVariant($light-blue);
  }
  &green {
    @include iconVariant($green);
  }
  &navy {
    @include iconVariant($navy);
  }
  &teal {
    @include iconVariant($teal);
  }
  &olive {
    @include iconVariant($olive);
  }
  &lime {
    @include iconVariant($lime);
  }
  &orange {
    @include iconVariant($orange);
  }
  &fuchsia {
    @include iconVariant($fuchsia);
  }
  &purple {
    @include iconVariant($purple);
  }
  &maroon {
    @include iconVariant($maroon);
  }
}