@mixin xshadow {
//  box-shadow: 0px 1px 6px 0 rgba(black, .2);
  border:1px solid lighten($gray-light,20);
}
@mixin headings($from: 1, $to: 6){
  @for $i from $from through $to{
    h#{$i}{
      @content
    }
  }
}