label.action {
  cursor: pointer;
}
input[type='checkbox'].action  {
  transform:scale(0);
  display: none;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}