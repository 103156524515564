.conversation {

  label {
    display: block;
    max-width: initial;
    margin-bottom: initial;
    font-weight: initial;
  }
  .message {
    display: block;

    &.own {
      text-align: right;
      .message-content {
        border-left-width: 2px;
        border-color: $brandy;
      }
      .datetime {
        position: absolute;
        right: auto;
        left: -10px;
        transform: translateX(-100%);
      }
    }
  }
  .message-content {
    position: relative;
    padding: $grid-gutter-width;
    display: inline-block;
    width: 70%;
    margin: $grid-gutter-width / 2;
//    background-color: $eastern-blue;
    border: 2px solid $eastern-blue;
  }
  .datetime {
    position: absolute;
    right: -10px;
    bottom: 10px;
    color: $gray-light;
    transform: translateX(100%);
    .time, .date {
      display: block;
    }
  }
  .author {
    font-weight: bold;
    color: $gray-light;
    span {
      color: $gray-dark;
    }
  }
  .body {
    padding: $main-distance /2 $main-distance /2 0;
    color: $gray-dark;
  }
}