$profile-md-size: $font-size-base * 10;

/* @todo: Ajustar el tamaño a pantallas más pequeñas */

.profile {
  padding: $main-distance;
  .profile-pic {
    height: $profile-md-size;
    width: $profile-md-size;
    background-color: $gray-lighter;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: auto;
    margin-top: $main-distance;

    i {
      font-size: $profile-md-size * .75;
      color: $gray-light;
    }
  }
}

.groups {
  margin-top: $main-distance;
  border-top: 1px solid $gray-lighter;
  div[class*="col-"] {
    min-height: 130px;
    border-right: 1px solid $gray-lighter;
    padding: $main-distance $main-distance / 2 + 15;
    &:first-child {
      padding-left: $main-distance + 15;
    }
    &:last-child {
      border-right: none;
    }
  }
  .title {
    display: block;
    font-weight: bold;
    margin-bottom: $main-distance / 2;
  }

  ul {
    padding-left: 10px;
    li {
      list-style: none;
    }
    &.limitations {
      .can {
        color: $brand-success
      }
      .cant {
        color: $brand-danger
      }
    }
  }
  .iluo-icon {
    margin-left: $main-distance;
  }
}