/*!
 *   XenonLTE v2.0.0
 *   Author: E.Alamo
 *	 Website: E.Alamo <http://example.com>
 *   License: GPL
!*/

//Bootstrap Variables & Mixins
//The core bootstrap code have not been modified. These files
//are included only for reference.
@import "bootstrap-custom";
//@import "../../bower_components/animate-sass/animate";
//MISC
//----
@import "variables";
@import "mixins";
@import "core";

//XENON
@import "xenon/variables";
@import "xenon/mixins";


//PAGES
@import "pages/user";
@import "pages/index";
//COMPONENTS
//-----------
@import "header";
@import "sidebar";
@import "sidebar-mini";
@import "control-sidebar";
@import "dropdown";
@import "forms";
@import "progress-bars";
@import "small-box";
@import "boxes";
@import "info-box";
@import "timeline";
@import "buttons";
@import "callout";
@import "alerts";
@import "navs";
@import "products";
@import "table";
@import "labels";
@import "direct-chat";
@import "users-list";
@import "carousel";
@import "modal";
@import "social-widgets";

//XENON
@import "xenon/conversation";
@import "xenon/forms-xenon";
@import "xenon/history";
@import "xenon/icons";
@import "xenon/iluo";
@import "xenon/manpower";
@import "xenon/navs";
@import "xenon/panels";
@import "xenon/magic";
@import "xenon/gridbox";
@import "xenon/site";
@import "xenon/details";
@import "xenon/timing";
@import "xenon/nested-list";
@import "xenon/kanban";
@import "xenon/switch";
//PAGES
//------
@import "mailbox";
@import "lockscreen";
@import "login_and_register";
@import "404_500_errors";
@import "invoice";
@import "profile";
//Plugins
//--------
//@import "bootstrap-social";
@import "fullcalendar";
@import "select2";
//Miscellaneous
//-------------
@import "miscellaneous";
@import "print";
@import "skins/skin-one";
th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
}
tr.rotate {
  background-color: transparent !important;
  border:none !important;
}
th.rotate {
  vertical-align: middle !important;
  border:none !important;
}
th.rotate > div {
  background-color: transparent;
  transform:
    /* Magic Numbers */
  translate(-65%, 50px)
    /* 45 is really 360 - 45 */
  rotate(315deg);
  width: 30px;
}
th.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}