$first-manpower-logo-color: #476ea2;
$second-manpower-logo-color: #6490c3;
$third-manpower-logo-color: #6e8e81;
$fourth-manpower-logo-color: #a8404c;
$fifth-manpower-logo-color: #c16c2d;
$rotation: 20deg;
$duration: 2s;
$delay: 0.1s;

$size: 24px;

$width: $size * 0.28571429;
$largeBar: $size * 1.42857143;
$margin: $size * 0.05;
$border-radius: $size * 0.17142857;

// Logo **********************
.manLogo {
  line-height: 1 !important;
  display: block;
  span {
    display: inline-block;
    vertical-align: bottom;
    height: $size;
    width: $width;
    background-color: black;
    border-radius: $border-radius;
    transform-origin: unset;
    transform: rotateZ($rotation);
    margin-right: $margin;
    border-width: 0px;
    line-height: 1 !important;

    &:nth-child(1) {
      background-color: $first-manpower-logo-color;
      border-color: $first-manpower-logo-color;
    }
    &:nth-child(2) {
      background-color: $second-manpower-logo-color;
      border-color: $second-manpower-logo-color;
    }
    &:nth-child(3) {
      background-color: $third-manpower-logo-color;
      border-color: $third-manpower-logo-color;
    }
    &:nth-child(4) {
      height: $largeBar;
      background-color: $fourth-manpower-logo-color;
      border-color: $fourth-manpower-logo-color;
    }
    &:nth-child(5) {
      background-color: $fifth-manpower-logo-color;
      border-color: $fifth-manpower-logo-color;
      vertical-align: top;
    }
  }
  &.loading {
    span {
      transition: all 0.3s ease;
      animation: bamboleo 1s ease;
      animation-iteration-count: infinite;
      &:nth-child(1) {
        animation-delay: 0.5s
      }
      &:nth-child(2) {
        animation-delay: 0.5s
      }
      &:nth-child(3) {
        animation-delay: 0.5s
      }
      &:nth-child(4) {
        height: $largeBar;
        animation-delay: 0.5s
      }
      &:nth-child(5) {
        animation-delay: 0.5s;
      }
    }

  }

  &.alt {
    span {
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
    }

  }
}

// Loader **********************
.page {
  //  We add the transition to the page to get an animation
  transition: filter .3s;
}

body.loading {
  overflow: hidden;
  height: 100vh;
  &:after {
    content: "";
    display: block;
    position: fixed;
    background: rgba(white, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
  }

  //  Blur page content
  .page {
    filter: blur(5px);
  }
}

// Main loader class;
.manLoader {
  background-color: white;
  padding: 33px 20px;
  border-radius: 100%;
  display: none;
  @include xshadow;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  &.static {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin: auto;
    display: block;
  }
  &.absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    .loading & {
      display: block;
    }
  }
  body.loading & {
    display: block;
  }
  span {
    display: inline-block;
    vertical-align: bottom;
    height: 35px;
    width: 10px;
    background-color: black;
    border-radius: 8px;
    transform-origin: center;
    transform: scale(0) rotateZ($rotation);
    margin-right: 2px;
    animation: bars $duration ease 0s infinite;
    border-width: 0px;

    &:nth-child(1) {
      background-color: $first-manpower-logo-color;
      border-color: $first-manpower-logo-color;
      animation-delay: $delay;
    }
    &:nth-child(2) {
      background-color: $second-manpower-logo-color;
      border-color: $second-manpower-logo-color;
      animation-delay: $delay * 2;
    }
    &:nth-child(3) {
      background-color: $third-manpower-logo-color;
      border-color: $third-manpower-logo-color;
      animation-delay: $delay * 3;
    }
    &:nth-child(4) {
      height: 50px;
      background-color: $fourth-manpower-logo-color;
      border-color: $fourth-manpower-logo-color;
      animation-delay: $delay * 4;
    }
    &:nth-child(5) {
      background-color: $fifth-manpower-logo-color;
      border-color: $fifth-manpower-logo-color;
      animation-delay: $delay * 5;
      vertical-align: top;
    }
  }
  &.alt {
    span {
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
    }

  }

}

@keyframes bamboleo {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 10px;
    opacity: 0;
  }
}

@keyframes bars {
  0%,
  100% {
    transform: scale(0) rotateZ($rotation);
  }
  30%,
  60% {
    transform: scale(1) rotateZ($rotation);
  }
}