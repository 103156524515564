.form-group-xenon {
  border: 1px solid #ddd;
  background-color: white;
  padding: $padding-base-vertical 0 0;
  label {
    text-transform: uppercase;
    padding-left: $padding-base-horizontal;
    color: $gray-light;
    font-size: 11px;
  }
  &.required {
    label.control-label {
      &:after {
        content: '*';
        margin-left: 5px;
      }
    }
  }
  &:hover {
    .form-control {
      background-color: lighten($gray-lighter, 3);
    }
  }

  .form-control,
  &.has-success .form-control,
  &.has-error .form-control,
  .form-control:focus {
    border: none;
    border-bottom: 1px solid transparent;
    font-size: 14px;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin: 0;
  }
  .form-control {
    &:focus {
      background-color: $gray-lighter;
    }
  }
  textarea {
    min-height: 120px;
  }
  .form-control-static {
    padding-left: 16px;
  }
  .input-group-addon {
    &:first-of-type:last-of-type {
      border-right-width: 1px !important;
    }
    &:last-child {
      border-right: none;
    }
    &:only-of-type:not(:last-child) {
      border-right: 1px solid $input-group-addon-border-color !important;
    }
    &:only-of-type:not(:first-child) {
      border-left: 1px solid $input-group-addon-border-color !important;
    }
    &:first-child {
      border-left: none;
    }
    border-bottom: none;
  }
  .help-block {
    padding: $padding-base-vertical $padding-base-horizontal;
    margin: 0;
    &:empty {
      display: none;
    }
  }

  &.has-success {
    label {
      color: $gray-light;
    }
    .form-control {
      color: darken($state-success-text, 10)
    }
  }
  &.has-error {
    border-left-color: $brand-danger;
    border-left-width: 3px;
    label {
      color: initial;
    }
    .form-control {
      border-bottom: 1px solid $gray-lighter;
    }
    .help-block {
      //color: $brand-danger;
    }
  }
  .hint-block {
    padding: $padding-base-vertical $padding-base-horizontal;
    margin: 0;
  }

  //  Other form elements enclosed in a div (widgets)
  > div:first-child {
    padding-left: $padding-base-horizontal;
  }
  .radio {
    padding-left: $padding-base-horizontal;
  }
  &.auto-hints {
    input[type="text"] {
      + .hint-block {
        transition: height .3s ease, padding .1s ease;
      }
      &:not(:focus) {
        + .hint-block {
          max-height: 0;
          overflow: hidden;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      &:focus {
        + .hint-block {
          max-height: 400px;
        }
      }
    }
  }

  .xlte-radio-list {
    display: block;
    height: auto;

    > div {
      display: flex;
      flex-wrap:wrap;
      justify-content: center;
      width: 100%;
    }
    input[type="radio"],input[type="checkbox"] {
      display: none;
      &:checked {
        + label {
          background: $light-blue;
          color:white;
          border-color: $light-blue;
          p {
            color:white;
          }
          //&:before {
          //  display: inline-block;
          //  vertical-align: baseline;
          //  height: 9px;
          //  width: 9px;
          //  margin-right: ;
          //  background-color: white;
          //  border-radius: 100%;
          //  content:"";
          //
          //}
        }
      }
    }
    label {
      border: 1px dashed $gray-light;
      flex: 1 1 auto;
      padding: 10px;
      margin: 8px;
      display: flex;
      text-align: center;
      align-items: stretch;
      display: flex;
      .label-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        b {
          display: block;
        }
      }
      p {
        color: lighten($gray-light,5);
        font-weight: initial;
        text-transform: none;
        margin-bottom: 0;
      }
      &:not(:last-child) {
        //margin-right: 10px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}