/*
 * Core: General Layout Style
 * -------------------------
 */
html,
body {
  min-height: 100%;
  .layout-boxed & {
    height: 100%;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Layout */
.wrapper {
  @include clearfix();
  min-height: 100%;
  position: relative;
  overflow: hidden;
  .layout-boxed & {
    max-width: 1250px;
    margin: 0 auto;
    min-height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    position: relative;
  }
}

.layout-boxed {
  background: url('${boxed-layout-bg-image-path}') repeat fixed;
}

/*
 * Content Wrapper - contains the main content
 * ```.right-side has been deprecated as of v2.0.0 in favor of .content-wrapper  ```
 */
body:not(.login-layout) {
  .content-wrapper,
  .right-side,
  .main-footer {
    //Using disposable variable to join statements with a comma
    $transition-rule: $transition-speed $transition-fn,
    margin $transition-speed $transition-fn;
    @include transition-transform($transition-rule);
    margin-left: $sidebar-width;
    z-index: 820;
    //Top nav layout
    .layout-top-nav & {
      margin-left: 0;
    }
    @media (max-width: $screen-xs-max) {
      margin-left: 0;
    }
    //When opening the sidebar on large screens
    .sidebar-collapse & {
      @media (min-width: $screen-sm) {
        margin-left: 0;
      }
    }
    //When opening the sidebar on small screens
    .sidebar-open & {
      @media (max-width: $screen-xs-max) {
        @include translate($sidebar-width, 0);
      }
    }
  }
}


.content-wrapper,
.right-side {
  min-height: calc(100vh - #{($navbar-height + $footer-height)});
  background-color: $body-bg;
  z-index: 800;


}

.main-footer {
  background: #fff;
  padding: 15px;
  color: #444;
  height: $footer-height;
  border-top: 1px solid $gray;
}

/* Fixed layout */
.fixed {
  .main-header,
  .main-sidebar,
  .left-side {
    position: fixed;
  }
  .main-header {
    top: 0;
    right: 0;
    left: 0;
  }
  .content-wrapper,
  .right-side {
    padding-top: 50px;
    @media (max-width: $screen-header-collapse) {
      padding-top: 100px;
    }
  }
  &.layout-boxed {
    .wrapper {
      max-width: 100%;
    }
  }
}

body.hold-transition {
  .content-wrapper,
  .right-side,
  .main-footer,
  .main-sidebar,
  .left-side,
  .main-header .navbar,
  .main-header .logo {
    /* Fix for IE */
    @include transition(none);
  }
}

/* Content */
.content {
  min-height: 250px;
  padding: 15px;
  @include container-fixed($grid-gutter-width * 2);
}

/* H1 - H6 font */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Source Sans Pro', sans-serif;
}

/* General Links */
a {
  color: $link-color;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: $link-hover-color;
}

/* Page Header */
.page-header {
  margin: 10px 0 20px 0;
  font-size: 22px;
  border-bottom: 1px solid #bec1c7;
  > small {
    color: #666;
    display: block;
    margin-top: 5px;
  }
}
