// Xenon nav pills variation
.nav-pills.nav-xenon {
  > li {
    // Links rendered as pills
    > a {
      border-left: 6px solid transparent;
//      padding: $padding-base-vertical $padding-base-horizontal $padding-base-vertical $padding-base-horizontal / 2 ;
      color: $gray;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      border-radius: $nav-pills-border-radius;
      i {
        vertical-align: middle;
        font-size: 1.2em;
        margin-right: 1em;
      }
    }
    + li {
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: $nav-pills-active-link-hover-bg;
        border-left: 6px solid $nav-pills-active-link-hover-bg;
        background-color: rgba($nav-pills-active-link-hover-bg, 0.1);
      }
    }
  }
}

.nav-pills.sidebar {
  > li {
    // Links rendered as pills
    margin: 0;
    > a {
      padding: 10px 5px;
      border-bottom: 1px solid lighten($dark-background, 5);
      border-left: 3px solid transparent;
      color: $gray-lighter;
      text-transform: uppercase;
      font-weight: 700;
      border-radius: $nav-pills-border-radius;
      &:hover,&:focus{
        background-color: lighten($dark-background,10);
      }
      i {
        vertical-align: middle;
        font-size: 1.2em;
        margin-right: 1em;
      }
    }
    + li {
    }


    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: white;
        border-width: 0;
        border-left: 3px solid $conifer;
        background-color: lighten($dark-background, 10);
      }
    }
  }
}

