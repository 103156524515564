html,
body {
  height: 100%;
}


.jumbotron {
  background-color: white;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

.not-set {
  color: #c55;
  font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
  position: relative;
  right: 0;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}

a.asc:after {
  content: /*"\e113"*/ "\e151";
}

a.desc:after {
  content: /*"\e114"*/ "\e152";
}

.sort-numerical a.asc:after {
  content: "\e153";
}

.sort-numerical a.desc:after {
  content: "\e154";
}

.sort-ordinal a.asc:after {
  content: "\e155";
}

.sort-ordinal a.desc:after {
  content: "\e156";
}

.grid-view th {
  white-space: nowrap;
}

.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}

.error-summary {
  color: #a94442;
  background: #fdf7f7;
//  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
  padding: 15px;
  border: none;
}

@media(max-width:767px) {
  .nav li > form > button.logout {
    display:block;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
  }
}

.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
  text-decoration: none;
}

.nav > li > form > button.logout:focus {
  outline: none;
}
