.live {
  display: inline-block;
  height: 14px;
  width: 14px;
  background-color: $maroon;
  border-radius: 100%;
  vertical-align: middle;
  position: relative;

  &.incoming {
    background-color: $gray-light;
  }
  &:after {
    display: none;

  }
  &.past {
    @extend .border-gray;
  }
  &.idle {
    background-color: $light-blue;
    &:after {
      display: none;
    }
  }
  &.missing {
    background-color: $yellow;
    animation: blink .4s linear;
    animation-iteration-count: infinite;

  }
  &-animated {
    &:after {
      content: "";
      display: block;
      position: absolute;
      background-color: transparent;
      border: 1px solid $maroon;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 100%;
      animation: pulse 2s ease;
      animation-iteration-count: infinite;
    }
  }
  &-lg {
    width: 20px;
    height: 20px;
  }

  &-sm {
    height: 10px;
    width: 10px;
  }
  &-xs {
    width: 6px;
    height: 6px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  40%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes blink {
  0%, 49% {
    background-color: transparent;
  }
  50%, 100% {
    background-color: $yellow;
  }
}