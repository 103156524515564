/*
 * Component: Info Box
 * -------------------
 */
.info-box {
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  box-shadow: $box-boxshadow;
  margin-bottom: 15px;
  color: $gray;

  .box-body &,.box-footer & {
    box-shadow: none;
    margin: 0;
  }
  small {
    font-size: 14px;
  }
  .progress {
    background: rgba(0, 0, 0, .2);
    margin: 5px -10px 5px 0px;
    height: 2px;
    &,
    & .progress-bar {
      @include border-radius(0);
    }
    .progress-bar {
      background: $gray;
    }
  }
  &[class*="bg-"] {
    .progress-bar {
      background: #fff !important;
    }
  }
}

$info-box-icon-size: 90px;
.info-box-icon {
  display: block;
  float: left;
  height: $info-box-icon-size;
  width: $info-box-icon-size;
  text-align: center;
  font-size: $info-box-icon-size / 2;
  line-height: $info-box-icon-size;
  > img {
    max-width: 100%;
  }
  $info-box-icon-size: 60px;

  .info-box-circle & {
    border-radius: 100%;
    height: $info-box-icon-size;
    width: $info-box-icon-size;
    font-size: $info-box-icon-size / 2;
    line-height: $info-box-icon-size;
    margin: 14px;
  }
}

.info-box-content {
  padding: 18px 10px 5px;
  margin-left: 90px;
  .with-progress & {
    padding: 5px 10px;
  }
}

.info-box-number {
  display: block;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}

.progress-description,
.info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box-text {
  text-transform: uppercase;
}

.info-box-more {
  display: block;
}

.progress-description {
  margin: 0;
}
