
.list-details {
  tbody {
    tr:first-child{
      td,th {
        border-top:none;
      }
    }
  }
}

// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-title {
  padding: 0;
  margin: 0;
  margin-bottom: 4px;
  font-weight: bold;
  text-transform: uppercase;
}

.list-detail-detail {

}