/*
 * Page: Login & Register
 * ----------------------
 */
@keyframes loginBars {
  0%, 100% {
    transform: translateY(0vh) rotateZ(-30deg)
  }
  50% {
    transform: translateY(-20vh) rotateZ(-30deg)
  }
}

.login-layout {
  background: url('imgs/login-bg.jpg');
  background-size: cover;
  $big-bar-size: 100px;
  [class^="big-bar"] {
    opacity: .2;
    height: $big-bar-size;
    border-radius: $big-bar-size;
    width: 30vw;
    position: fixed;
    transform: rotateZ(-30deg);
    animation: loginBars 14s cubic-bezier(.39, 1.02, .78, .25);
    animation-iteration-count: infinite;
  }
  .big-bar1 {
    bottom: -1vh;
    left: -9vw;
    border: 2px solid $first-manpower-logo-color;
    animation-delay: .5s;
  }
  .big-bar2 {
    bottom: $big-bar-size * 1.5;
    left: -7vw;
    border: 2px solid $second-manpower-logo-color;
    animation-delay: .9s;

  }
  .big-bar3 {
    bottom: $big-bar-size * 3;
    left: -6vw;
    border: 2px solid $third-manpower-logo-color;
    animation-delay: 1.3s;

  }
  .big-bar4 {
    bottom: $big-bar-size * 4;
    left: -9vw;
    border: 2px solid $fourth-manpower-logo-color;
    animation-delay: 1.7s;
  }
  .big-bar5 {
    bottom: $big-bar-size * 5;
    left: -12vw;
    border: 2px solid $fifth-manpower-logo-color;
    animation-delay: 1.9s;

  }
  .wrapper {
    background-color: rgba(white, 1) !important;
  }
  .content-wrapper {
    background: transparent !important;
  }
  .login-form {
    margin-top: 40vh;
    font-size: 16px;
  }
  .form-control {
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
  }
  button[type="submit"] {
    padding: $main-distance $main-distance * 2;
    display: block;
  }
}

.login-form {
  input {
    border-radius: 30px;
    padding: $main-distance;
    height: auto;
  }
}

.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
  a {
    color: #444;
  }
}

.login-page,
.register-page {
  background: $gray;
}

.login-box,
.register-box {
  width: 360px;
  margin: 7% auto;
  @media (max-width: $screen-sm) {
    width: 90%;
    margin-top: 20px;
  }
}

.login-box-body,
.register-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
  .form-control-feedback {
    color: #777;
  }
}

.login-box-msg,
.register-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.social-auth-links {
  margin: 10px 0;
}
