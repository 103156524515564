.x-gridview {

  .grid-summary {
    margin-right: 10px;
    color: $gray-light;
  }

  .box-body {
    .filters {
      .form-control {
      }
    }
  }

  .box-header {
    .btn{
      vertical-align: baseline;
    }
  }
  .filters {
    display: none;
    .highlight {
      background-color: #ffeea0;
    }
  }

  .btn-filter {
    padding-top: 4px;
    vertical-align: bottom;
    padding-bottom: 3px;
    margin-right: 10px;
  }

  .toggle-filters:checked + .box {
    .btn-filter {
      background-color: $teal;
      color:white;
    }
    table {
      .filters {
        display: table-row;
      }
    }
  }


  #prueba:checked {
    ~ * table .filters {
      //    display: table-row;
    }
  }
}
table {
  .btn-group {
    white-space: nowrap;
    > .btn {
      float: none;
      display: inline-block;
    }
  }
}
.box-header {
  .gridview-actions {
    margin-right: 10px;
  }
  .pagination {
    vertical-align: middle;
    margin: 0;
  }
}
.fit-column {
  width: 1%;
  white-space: nowrap;
}