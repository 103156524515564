$numberWidth: 25px;
.kanban {
  list-style: none;
  padding: 0;

  &-number, &-content {
    display: inline-block;
    vertical-align: middle;
  }
  &-item {
    @extend .box;
  }

  &-title {
    font-weight: bolder;
  }
  &-number {
    width: $numberWidth;
    text-align: center;
    font-weight: bolder;
  }

  &-content {
    padding: $box-padding $box-padding / 2;
    width: calc(100% - #{($numberWidth+6)});
  }

  &-actions {
    display: block;
    border-top: 1px solid $gray-lighter;
    i {
      display: inline-block;
    }
  }
}