.nested-list {
  list-style: none;
   li {
     padding: $main-distance;
     border-top: 1px solid $gray-light;
     margin-top: $main-distance;
   }
}

.list-smooth {
  list-style: none;
  padding-left: 0;
  > * {
    border-bottom: 1px solid $gray-lighter;
    padding-bottom: 4px;
    padding-top: 4px;
  }
}