/*
 * General: Miscellaneous
 * ----------------------
 */
// 10px padding and margins
$colors: (
                ("white", white),
                ("gray", $gray),
                ("gray-light", #eee),
                ("black", $black),
                ("red", $red),
                ("yellow", $yellow),
                ("aqua", $aqua),
                ("blue", $blue),
                ("light-blue", $light-blue),
                ("green", $green),
                ("navy", $navy),
                ("teal", $teal),
                ("olive", $olive),
                ("lime", $lime),
                ("orange", $orange),
                ("fuchsia", $fuchsia),
                ("purple", $purple),
                ("maroon", $maroon),
);

.color-palette {
  padding: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.x-color-sample {
  padding: 60px 30px;
  margin: 20px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.pad {
  padding: 10px;
}

.margin {
  margin: 10px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-bottom-none {
  margin-bottom: 0;
}

.margin-r-5 {
  margin-right: 5px;
}

// Display inline
.inline {
  display: inline;
}

.vertical-align, table.vertical-align td {
  vertical-align: middle !important;
}

// Description Blocks
.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
  &.margin-bottom {
    margin-bottom: 25px;
  }
  > .description-header {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
  }
  > .description-text {
    text-transform: uppercase;
  }
}

[class*="-gradient"]:only-child, [class*="bg-"]:only-child:not(.bg-white),[class*="bg-"].btn:not(.bg-white) {
  color: white;
  .btn-box-tool, a:not([class*="bg-"]) {
    color: white;
  }
}

%white-font {
  color: white;
}

@each $color in $colors {

  // *** FLAT BACKGROUNDS
  /* #{nth($color,1)}*/
  .bg-#{nth($color,1)}:not(td):not(tr) {
    background-color: #{nth($color,2)} !important;
    //    Exceptions
    @if (nth($color,1) =='gray') {
      color: #e6e5e5;
    } @else if (nth($color,1) =='white') {
      color: $gray-dark
    } @else {
      //@extend %white-font;
    }

  }
  td.bg-#{nth($color,1)}, tr.bg-#{nth($color,1)} {
    $lighted: nth($color, 2);
    background-color: rgba($lighted, .2) !important;
    color: initial !important;
    .btn-default {
      background-color: white;
    }
  }

  //  *** GRADIENT BACKGROUNDS
  $gradient-intensity: 10%;
  .bg-#{nth($color,1)}-gradient {
    $grad-color: nth($color, 2);

    //    Exceptions
    @if (nth($color,1) =='black') {
      @include gradient($grad-color, $grad-color, lighten($grad-color, $gradient-intensity));

    } @else {
      @include gradient($grad-color, darken($grad-color, $gradient-intensity), $grad-color);

    }
  }

  //  *** ACTIVE BACKGROUND

  .bg-#{nth($color,1)}-active {
    $active-color: nth($color, 2);
    background-color: darken($active-color, 10%) !important;

  }

  //  *** TEXT COLORS
  .text-#{nth($color,1)} {
    color: #{nth($color,2)} !important;
  }

  .label.border-#{nth($color,1)} {
    color: #{nth($color,2)}
  }

  // *** BORDER ONLY
  .border-#{nth($color,1)} {
    border: 1px solid #{nth($color,2)} !important;
    //color: #{nth($color,2)} !important;
    background-color: transparent !important;
  }


}

[class*="border-"].btn-lg {
  @include button-size($padding-large-vertical - 2, $padding-large-horizontal - 2, $font-size-large, $line-height-large, $btn-border-radius-large);
}

[class*="border-"].btn-sm {
  @include button-size($padding-small-vertical - 2, $padding-small-horizontal - 2, $font-size-small, $line-height-small, $btn-border-radius-small);
}

[class*="border-"].xs {
  @include button-size($padding-xs-vertical - 2, $padding-xs-horizontal - 2, $font-size-small, $line-height-small, $btn-border-radius-small);
}

[class*="border-"].btn {
  padding-top: 2px;
  padding-bottom: 2px;
}

//Disabled!
[class^="bg-"].disabled {
  @include opacity(.65);
}

.link-muted {
  color: darken($gray, 30%);
  &:hover,
  &:focus {
    color: darken($gray, 40%);
  }
}

.link-black {
  color: #666;
  &:hover,
  &:focus {
    color: #999;
  }
}

// Hide elements by display none only
.hide {
  display: none !important;
}

// Remove borders
.no-border {
  border: 0 !important;
}

// Remove padding
.no-padding {
  padding: 0 !important;
}

// Remove margins
.no-margin {
  margin: 0 !important;
}

// Remove box shadow
.no-shadow {
  box-shadow: none !important;
}

// Unstyled List
.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-group-item {
  background-color: transparent;
}

.list-group-unbordered {
  > .list-group-item {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

// Remove border radius
.flat {
  @include border-radius(0 !important);
}

.text-bold {
  &, &.table td, &.table th {
    font-weight: 700;
  }
}

.text-sm {
  font-size: 12px;
}

// _fix for sparkline tooltip
.jqstooltip {
  padding: 5px !important;
  width: auto !important;
  height: auto !important;
}

//Description Block Extension
.description-block {
  .description-icon {
    font-size: 16px;
  }
}

//Remove top padding
.no-pad-top {
  padding-top: 0;
}

//Make position static
.position-static {
  position: static !important;
}

//List utility classes
.list-header {
  font-size: 15px;
  padding: 10px 4px;
  font-weight: bold;
  color: #666;
}

.list-separator {
  height: 1px;
  background: $box-border-color;
  margin: 15px 0 9px 0;
}

.list-link {
  > a {
    padding: 4px;
    color: #777;
    &:hover {
      color: #222;
    }
  }
}

//Light font weight
.font-light {
  font-weight: 300;
}

//User block
.user-block {
  @include clearfix();
  img {
    width: 40px;
    height: 40px;
    float: left;
  }
  .username,
  .description,
  .comment {
    display: block;
    margin-left: 50px;
  }
  .username {
    font-size: 16px;
    font-weight: 600;
  }
  .description {
    color: #999;
    font-size: 13px;
  }
  &.user-block-sm {
    img {
      @extend .img-sm;
    }
    .username,
    .description,
    .comment {
      margin-left: 40px;
    }
    .username {
      font-size: 14px;
    }
  }
}

//Image sizes
.img-sm,
.img-md,
.img-lg {
  float: left;
}
.img-centered {
  display: block;
  float: none !important;
  margin: auto;
}

.img-sm {
  width: 30px !important;
  height: 30px !important;
  + .img-push {
    margin-left: 40px;
  }
}

.img-md {
  width: 90px;
  height: 90px;
  + .img-push {
    margin-left: 70px;
  }
}

.img-lg {
  width: 130px;
  height: 130px;
  + .img-push {
    margin-left: 110px;
  }
}

// Image bordered
.img-bordered {
  border: 3px solid $gray;
  padding: 3px;
}

.img-bordered-sm {
  border: 2px solid $gray;
  padding: 2px;
}

//General attachemnt block
.attachment-block {
  border: 1px solid $box-border-color;
  padding: 5px;
  margin-bottom: 10px;
  background: #f7f7f7;

  .attachment-img {
    max-width: 100px;
    max-height: 100px;
    height: auto;
    float: left;
  }
  .attachment-pushed {
    margin-left: 110px;
  }
  .attachment-heading {
    margin: 0;
  }
  .attachment-text {
    color: #555;
  }
}

.connectedSortable {
  min-height: 100px;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sort-highlight {
  background: white !important;
  border: 1px dashed #ddd;
  margin-bottom: 10px;
  list-style: none !important;
}

.full-opacity-hover {
  @include opacity(.65);
  &:hover {
    @include opacity(1);
  }
}

// Charts
.chart {
  position: relative;
  overflow: hidden;
  width: 100%;
  svg,
  canvas {
    width: 100% !important;
  }
}

.x-gridview {
  table {
    [class^="ion-"] {
      font-size: 20px;
      line-height: $font-size-base;
    }
  }
}

// External links
a[href^='http'], a[href^='//'] {
  &:after {
    content: "\e164";
    @extend .glyphicon;
    font-size: 8px;
    margin-left: 5px;
  }
}


.empty {
  @extend .pad;
}

.day.disabled {
  color: #eee !important;
}

.min-height-100{
  min-height: 100px;
}

